<template>
  <div>
    <div class="min-vh-100">
      <CRow class="w-100 no-gutters px-3 px-sm-0">
        <CCol cols="6">
          <h1 class="mr-sm-4 header text-uppercase">EDIT Question/Answer</h1>
        </CCol>
        <CCol cols="6" class="text-right">
          <button
            type="button"
            class="btn btn-main-2 button"
            :disabled="form.feeling.length == 5"
            @click="addEditData(true, 0)"
          >
            ADD
          </button>
        </CCol>
      </CRow>
      <div
        class="bg-white-border px-4 px-sm-5 mt-3 py-4"
        v-bind:class="$isLoading ? 'd-none' : 'd-block'"
      >
        <InputText
          class="mb-4"
          textFloat="Header"
          placeholder="Header"
          type="text"
          name="name"
          isRequired
          v-model="form.header"
          :isValidate="$v.form.header.$error"
          :v="$v.form.header"
        />

        <InputText
          class="mb-4"
          textFloat="Subject Detail"
          placeholder="Subject"
          type="text"
          name="name"
          isRequired
          v-model="form.detail"
          :isValidate="$v.form.detail.$error"
          :v="$v.form.detail"
        />
        
        <InputText
          class="mb-4"
          textFloat="Subject Satisfied Status"
          placeholder="Subject Satisfied Status"
          type="text"
          name="name"
          isRequired
          v-model="form.title"
          :isValidate="$v.form.title.$error"
          :v="$v.form.title"
        />

        <b-table striped hover :items="items" :fields="fields">
          <template v-slot:cell(normalImage)="data">
            <div class="d-flex justify-content-center">
              <div
                class="image-bank-logo"
                v-bind:style="{
                  'background-image': 'url(' + data.item.normalImage + ')',
                }"
              ></div>
              <div
                class="image-bank-logo"
                v-bind:style="{
                  'background-image': 'url(' + data.item.selectImage + ')',
                }"
              ></div>
            </div>
          </template>
          <template v-slot:cell(id)="data">
            <b-button
              variant="link"
              class="px-1 py-0"
              @click="addEditData(false, data.item.id, data.item, data.index)"
            >
              <font-awesome-icon
                icon="pencil-alt"
                class="text-warning"
                title="View"
              />
            </b-button>
            <b-button variant="link" class="px-1 py-0">
              <font-awesome-icon
                icon="trash-alt"
                class="text-danger"
                @click="deleteItem(data.item.id, data.index)"
              />
            </b-button>
          </template>
        </b-table>

        <p class="text-danger" v-if="$v.form.feeling.$error">
          Please add at least one question/answer
        </p>

        <!-- <InputTextArea
          class="mb-4"
          textFloat="Details"
          placeholder="Details"
          type="text"
          name="details"
          isRequired
          v-model="form.detail"
          :isValidate="$v.form.detail.$error"
          :v="$v.form.detail"
        /> -->

        <TextEditor
          id="textCallBack"
          textFloat="#ต้องการให้ติดต่อกลับ"
          v-model="form.callback"
          placeholder=""
          name="textCallBack"
          :v="$v.form.callback"
          @onDataChange="(val) => (form.callback = val)"
        />

        <TextEditor
          id="textUnCallBack"
          textFloat="#ไม่ต้องการให้ติดต่อกลับ"
          v-model="form.unCallback"
          placeholder=""
          name="textUnCallBack"
          :v="$v.form.unCallback"
          @onDataChange="(val) => (form.unCallback = val)"
        />

        <b-row class="mt-5">
          <b-col md="6">
            <strong class="text-black">Satisfied Status</strong>
            <b-form-checkbox
              v-model="form.satisfiedStatus"
              name="check-button"
              switch
            >
              <b class="text-black">
                {{ form.satisfiedStatus ? "On" : "Off" }}
              </b>
            </b-form-checkbox>
          </b-col>
          <b-col md="6" class="text-sm-right">
            <button
              type="button"
              class="btn btn-success btn-details-set ml-md-2 text-uppercase"
              @click="saveForm"
              :disabled="isDisable"
            >
              Save
            </button>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- Modal Result -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />

    <b-modal
      id="addEditData"
      ref="addEditData"
      title="BootstrapVue"
      hide-header
      hide-footer
      centered
      size="lg"
    >
      <div class="modal-header border-bottom-0">
        <h2 class="mb-1 font-weight-bold">Add/Edit Question/Answer</h2>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('addEditData')"
        >
          ×
        </button>
      </div>
      <div class="modal-body">
        <b-row>
          <b-col md="6">
            <InputText
              class="mb-4"
              textFloat="Name"
              placeholder="Name"
              type="text"
              name="name"
              isRequired
              v-model="modalData.name"
              :isValidate="$v.modalData.name.$error"
              :v="$v.modalData.name"
            />
          </b-col>
          <b-col md="6">
            <InputText
              class="mb-4"
              textFloat="Sort Order"
              placeholder="Sort Order"
              type="text"
              name="sortby"
              isRequired
              v-model="modalData.sortOrder"
              :isValidate="$v.modalData.sortOrder.$error"
              :v="$v.modalData.sortOrder"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <UploadFile
              textFloat="Thumbnail"
              placeholder="Please choose file"
              format="image"
              name="logo"
              text="*Please upload only file .png, .jpg only with 100 x 100 px and less than 10 MB"
              :fileName="modalData.normalImg"
              v-model="modalData.normalImg"
              v-on:onFileChange="onImageChange"
              v-on:delete="deleteImage"
              isRequired
              :v="$v.modalData.normalImg"
              :isValidate="$v.modalData.normalImg.$error"
              :checkRegRatio="true"
            />
            <div
              class="preview-box square-ratio bank-img"
              v-bind:style="{
                'background-image': 'url(' + modalData.normalImg + ')',
              }"
            >
              <img
                src="@/assets/images/icons/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoading"
              />
            </div>
          </b-col>
          <b-col md="6">
            <UploadFile
              textFloat="Thumbnail (Selected)"
              placeholder="Please choose file"
              format="image"
              name="mobileapplogo"
              text="*Please upload only file .png, .jpg only with 100 x 100 px and less than 10 MB"
              :fileName="modalData.selectImg"
              v-model="modalData.selectImg"
              v-on:onFileChange="onImageSelectedChange"
              v-on:delete="deleteImageSelected"
              :v="$v.modalData.selectImg"
              :isValidate="$v.modalData.selectImg.$error"
              isRequired
            />
            <div
              class="preview-box square-ratio bank-img"
              v-bind:style="{
                'background-image': 'url(' + modalData.selectImg + ')',
              }"
            >
              <img
                src="@/assets/images/icons/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingSelected"
              />
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col md="6">
            <b-button
              @click="$bvModal.hide('addEditData')"
              class="btn-details-set"
              :disabled="isDisable"
              >CANCEL</b-button
            >
          </b-col>
          <b-col md="6" class="text-sm-right">
            <button
              v-if="id != 0"
              type="button"
              class="btn btn-success btn-details-set ml-md-2 text-uppercase"
              @click="saveData"
              :disabled="isDisable"
            >
              Save
            </button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import { required, requiredIf } from "vuelidate/lib/validators";
import UploadFile from "@/components/inputs/UploadFile";
import TextEditor from "@/components/inputs/TextEditor";

export default {
  name: "EditFeedbackQuestion",
  components: {
    InputText,
    InputTextArea,
    ModalAlert,
    ModalAlertError,
    UploadFile,
    TextEditor,
  },
  data() {
    return {
      modalMessage: "",
      isDisable: false,
      id: this.$route.params.id,
      isBusy: false,
      isLoading: false,
      isLoadingSelected: false,
      isAdd: false,
      index: 0,
      items: [],
      form: {
        title: "",
        detail: "",
        header: "",
        feeling: {},
        removeList: [],
        callback: "",
        unCallback: "",
        satisfiedStatus: false,
      },
      modalData: {
        id: 0,
        name: "",
        sortOrder: "",
        normalImg: "",
        selectImg: "",
      },
      fields: [
        {
          key: "normalImage",
          label: "Thumbnail",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "sortOrder",
          label: "Sort Order",
        },
        {
          key: "id",
          label: "Action",
        },
      ],

      // switch input
    };
  },
  validations: {
    form: {
      title: { required },
      detail: { required },
      header: { required },
      feeling: {
        required: requiredIf(function() {
          return this.form.feeling.length == 0;
        }),
      },
      callback: {},
      unCallback: {},
    },
    modalData: {
      name: { required },
      sortOrder: { required },
      normalImg: { required },
      selectImg: { required },
    },
  },
  created: async function() {
    this.$isLoading = true;
    await this.getData();
    this.$isLoading = false;
  },
  methods: {
    getData: async function() {
      this.isBusy = true;
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/feedback/feeling`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.form = resData.detail;
        this.items = resData.detail.feeling;

        this.satisfiedStatus = resData.detail.satisfiedStatus;
        this.isBusy = false;
      }
    },
    deleteItem(id, index) {
      if (id != 0) this.form.removeList.push(id);
      this.form.feeling.splice(index, 1);
    },
    addEditData(isAdd, id, data, index) {
      this.isAdd = isAdd;
      if (this.isAdd) {
        this.clearData();
      } else {
        this.index = index;
        this.modalData.id = data.id;
        this.modalData.name = data.name;
        this.modalData.sortOrder = data.sortOrder;
        this.modalData.normalImg = data.normalImage;
        this.modalData.selectImg = data.selectImage;
      }
      this.$refs["addEditData"].show();
    },
    onImageChange(img) {
      this.isLoading = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoading = false;
        this.isDisable = false;
        this.modalData.normalImg = this.images;
      };
    },
    deleteImage(img) {
      this.modalData.normalImg = null;
    },
    onImageSelectedChange(img) {
      this.isLoadingSelected = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingSelected = false;
        this.isDisable = false;
        this.modalData.selectImg = this.images;
      };
    },
    deleteImageSelected(img) {
      this.modalData.selectImg = null;
    },
    saveImagetoDb: async function(img) {
      var imgData = {
        base64: img,
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    saveData() {
      this.$v.modalData.$touch();
      if (this.$v.modalData.$error) return;

      this.$refs["addEditData"].hide();
      this.$v.modalData.$reset();

      if (this.isAdd) {
        let data = {
          id: this.modalData.id,
          name: this.modalData.name,
          sortOrder: this.modalData.sortOrder,
          normalImage: this.modalData.normalImg,
          selectImage: this.modalData.selectImg,
        };
        this.form.feeling.push(data);
      } else {
        var existData = this.form.feeling[this.index];

        existData.id = this.modalData.id;
        existData.name = this.modalData.name;
        existData.sortOrder = this.modalData.sortOrder;
        existData.normalImage = this.modalData.normalImg;
        existData.selectImage = this.modalData.selectImg;
      }
    },
    clearData() {
      this.modalData.name = "";
      this.modalData.sortOrder = 0;
      this.modalData.normalImg = "";
      this.modalData.selectImg = "";
    },
    saveForm: async function() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isDisable = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/feedback/feelingSave`,
        null,
        this.$headers,
        this.form
      );
      this.modalMessage = resData.message;
      this.isDisable = false;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();

        this.$isLoading = true;
        this.getData();
        this.$isLoading = false;
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-black {
  color: black;
}
</style>
